<script>
export default {
  props: ['project'],
};
</script>

<template>
  <router-link
      :to="project.path"
      class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark flex-col"
      aria-label="Single Project"
  >
    <div style="height:58%; overflow:hidden">
      <img
          :src="project.img"
          :alt="project.title"
          class="rounded-t-xl border-none object-fill"
      />
    </div>
    <div class="flex items-center flex-col h-full pt-11">
      <p
          class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
      >
        {{ project.title }}

      </p>
      <span
          class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
      >{{ project.category }}</span
      >
    </div>
  </router-link>
</template>

<style lang="scss" scoped></style>
