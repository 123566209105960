// Projects data.

const projects = [
	{
		id: 1,
		title: 'UniVR Studio Onboarding',
		category: 'Project Management',
		img: require('@/assets/images/Projects/Onboarding/UniVR-card.png'),
		path : '/projects/Onboarding',
	},
	{
		id: 2,
		title: 'UniVR Studio CRM ',
		category: 'Change Management Strategy',
		img: require('@/assets/images/Projects/Bloom/CRM-card.png'),
		path : '/projects/Bloom',
	},
	{
		id: 3,
		title: 'Ocaly',
		category: 'Personal Project',
		img: require('@/assets/images/Projects/Ocaly/Ocaly-card.png'),
		path : '/projects/Ocaly',
	},
	{
		id: 4,
		title: 'New Era Festival',
		category: 'Project Management',
		img: require('@/assets/images/Projects/NewEraFestival/NewEra-card.png'),
		path : '/projects/NewEra',
	},
	{
		id: 5,
		title: 'Parcoursup',
		category: 'Project Management',
		img: require('@/assets/images/Projects/Parcoursup/parcoursup-card.png'),
		path : '/projects/Parcoursup',
	},
	{
		id: 6,
		title: 'Rescue',
		category: 'Personal Project',
		img: require('@/assets/images/Projects/Rescue/Rescue-card.png'),
		path : '/projects/Rescue',
	},
	{
		id: 7,
		title: 'DailyAthlon',
		category: 'Data Analysis',
		img: require('@/assets/images/Projects/DailyAthlon/DailyAthlon-card.png'),
		path : '/projects/DailyAthlon',
	},
];

export default projects;
