export const socialLinks = [
	{
		id: 1,
		name: 'Youtube',
		icon: 'youtube',
		url: 'https://www.youtube.com/@alexiscrocillapro',
	},
	{
		id: 2,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/alexiscrocilla',
	},
	{
		id: 3,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/alexiscrocilla',
	},
	{
		id: 4,
		name: 'Email',
		icon: 'mail',
		url: 'mailto:a.crocilla@digila.fr',
	},
];
