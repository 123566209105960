<script>
export default {
  props: ['title', 'type'],
  data: () => {
    return {

    };
  },
};
</script>

<template>
  <button :type="type || 'submit'">{{ title }}</button>
</template>

<style lang="scss" scoped></style>
